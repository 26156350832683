import { IEnvironment, defaultEnvironment } from '@geovelo-frontends/commons';

let _environment: IEnvironment & { amplitude: { apiKey: string }; backendMediaUrl: string } = {
  ...defaultEnvironment,
  source: 'website',
  apiKey: '0f8c781a-b4b4-4d19-b931-1e82f22e769f',
  backendMediaUrl: 'https://geovelo-annual-recaps-dev.s3.fr-par.scw.cloud',
  amplitude: { apiKey: '584fa9dd01a566e69bc4bb6c746a5f81' },
};

if (!process.env.REACT_APP_ENV || process.env.REACT_APP_ENV === 'development') {
  _environment = {
    ..._environment,
  };
}

if (process.env.REACT_APP_ENV === 'staging') {
  _environment = {
    ..._environment,
    backendMediaUrl: 'https://geovelo-annual-recaps-dev.s3.fr-par.scw.cloud',
  };
}

if (process.env.REACT_APP_ENV === 'production') {
  _environment = {
    ..._environment,
    backendMediaUrl: 'https://geovelo-annual-recaps.s3.fr-par.scw.cloud',
  };
}

export const environment = _environment;
